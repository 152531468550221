@mixin tableContainer {
  
table {
  margin: 20px auto;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}
th, td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

th {
  background-color: #00437f;
  color: white;
}
tr:nth-child(even) {
  background-color: #f9f9f9;
}
}

h3 {
  color: #409d49;
  padding: 10px 0px;
}

@mixin container {
  display: flex;

  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;

  h2 {
    color: #02539b;
    background-color: aliceblue;
  }

  
.section {   

  margin-bottom: 20px;
  width: calc(100% - 15px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(192, 249, 231, 0.408);
  padding: 15px;
  margin: 10px 0;
  //box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.8);
  
  &-title {
    font-size: 22px;
    color: #02539b;
    margin: 5px;
  }


  &-gris {
   // background-color: #f0f0f0; /* Gris claro */
    padding: 15px;
    margin: 10px 0;
    }

    &-blanco {
    background-color: #ffffff; /* Blanco */
    padding: 15px;
    margin: 10px 0;
  //  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.8);

    }
}
/* Margen reducido para títulos y párrafos */
.titulo-principal {
  font-size: 28px;
  color: #2c3e50;
  margin: 20px 0 10px 0;
}

.titulo-seccion {
  font-size: 22px;
  color: #34495e;
  margin: 5px;
}

.subtitulo {
  font-size: 18px;
  color: #41494a;
  margin: 5px;
}

.parrafo, .parrafo-ejemplo {
margin: 5px;
color: #2c3e50;
}

.parrafo-ejemplo {
  font-style: italic;
}
}



// SCROLL
@mixin scrollable-container {
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: #818283;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #c4c4c4;
      box-shadow: rgb(196,196,196,0.25);
    }
    ::-webkit-scrollbar-thumb:active {
      background-color: #d7d7d7;
    }
    ::-webkit-scrollbar-track {
      background: #d7d7d7;
      border-radius: 4px;
    }
  
    ::-webkit-scrollbar-track:hover,
    ::-webkit-scrollbar-track:active {
      background: #e9e9e9;
    }
  }

  @mixin tipografia-explicacon-leccion {
    font-family: 'Arial', sans-serif; /* Fuente legible y común */
    font-size: 16px;
    line-height: 16px;
  }

  ////////////////////////////////////////////
//    TRANSFORMACIONES                    //
////////////////////////////////////////////

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -o-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

////////////////////////////////////////////
//     TRANSITIONES Y ANIMACIONES         //
////////////////////////////////////////////

////////////////////////////////////////////
//     TIPO Y TIEMPO DE TRANSICION        //
//     Por ej: all 0.5s ease-in-out       //
//: transition:                           //
// property   all/ fadeIn / fadeOut       //
// duration   0.5s  (s, ms)               //
// timing-function   easeIn/ easeOut      //
//                                        //
////////////////////////////////////////////

@mixin transition($transitions) {
  -moz-transition: $transitions;
  -ms-transition: $transitions;
  -o-transition: $transitions;
  -webkit-transition: $transitions;
  transition: $transitions;
}

// ANIMACION ROTACION

@mixin rotate-animation($from: 0deg, $to: 360deg) {
  @keyframes rotate {
    from {
      transform: rotate($from);
    }

    to {
      transform: rotate($to);
    }
  }

  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
///